import {combineReducers} from "redux"
import error from "./errorReducer"
import user from "./userReducer"
import farms from "./farmsReducer"
import language from "./localizationReducer"
import history from "./historyReducer"
import location from "./locationReducer"
import footerActions from "./footerReducer"
import settings from "./settingsReducer"
import view from "./viewPropertiesReducer"
import loading from "./loadingReducer"
import farmDevices from "./devicesReducer"
import animals from "./animalsReducer"
import braintree from "./braintreeReducer"
import animalDocuments from "./animalDocumentsReducer"
import grid from "./gridReducer"
import events from "./eventsReducer"
import dictionary from "./dictionaryReducer"
import shadows from "./shadowReducer"
import technologyGroup from "./technologyGroupReducer"
import {reducer as reduxFormReducer} from 'redux-form';
import {reducer as modal} from 'redux-modal'
import {reducer as notificationsReducer} from 'reapop';
import mqtt from "./mqttReducer"
import iotAlarms from "./iotAlarmsReducer"
import inactive from "./inactiveReducer"
import news from "./newsReducer"
import terminal from "./terminalReducer"
import documents from "./documentsReducer"
import dashboard from "./dashboardReducer"
import sns from "./snsReducer"
import time from "./timeReducer"
import raports from "./raportsReducer"
import {routerReducer as routing} from "react-router-redux"
import notificationCenter from "./notificationReducer"
import workers from "./workersReducer"
import aggregatedData from "./aggregatedReducer"
import dataLoader from "./dataLoaderReducer";
import rfid from "./rfidReducer";
import lastUsed from "./lastUsedReducer";
import feeding from "./feedingReducer";
import logs from "./logsReducer";
import commandsHistory from "./commandsHistoryReducer";
import inventory from "./inventoryReducer";
import settlement from "./settlementReducer";
import eventCounter from "./eventCountersReducer";
import maintenance from "./maintenanceReducer";
import mainTab from "./mainTabReducer";
import task from "./taskReducer";
import groups from "./groupReducer";

export default combineReducers({
    animals,
    error,
    user,
    farms,
    language,
    modal,
    history,
    location,
    view,
    news,
    notifications: notificationsReducer(),
    footerActions,
    settings,
    loading,
    farmDevices,
    animalDocuments,
    grid,
    shadows,
    events,
    dictionary,
    technologyGroup,
    mqtt,
    raports,
    routing,
    inactive,
    terminal,
    form: reduxFormReducer, // mounted under "form"
    braintree,
    sns,
    documents,
    dashboard,
    time,
    notificationCenter,
    workers,
    aggregatedData,
    dataLoader,
    logs,
    rfid,
    lastUsed,
    feeding,
    commandsHistory,
    inventory,
    iotAlarms,
    settlement,
    maintenance,
    eventCounter,
    mainTab,
    task,
    groups
})
