import _ from "lodash";

const initialState = {
    data: new Map(),
    fetchingMap: new Map()
};

function insertIntoData(state, action) {
    let data = new Map(state.data);
    let devID = action.payload.DevID || action.meta.DevID;
    let dataArray = Array.isArray(action.payload) ? action.payload : [action.payload];
    let array = (data.get(devID) || []).slice(0);
    for (let row of dataArray) {
        let index = _.findIndex(array, {AggTi: row.AggTi});
        if (index !== -1) {
            array[index] = row;
        } else {
            array.push(row);
        }
    }
    array.sort((a, b) => b.AggTi - a.AggTi);
    data.set(devID, array);
    return data;
}

export default function aggregatedReducer(state = initialState, action) {
    if (action.type === "USER_LOGOUT_FULFILLED" || action.type === "CHANGE_FARM") {
        return initialState
    } else if (action.type === "FETCH_AGGREGATED_DATA_PENDING") {
        let map = new Map(state.fetchingMap);
        map.set(action.meta.DevID, true);
        return {
            ...state,
            fetchingMap: map
        };
    } else if (action.type === "FETCH_AGGREGATED_DATA_FULFILLED") {
        try {
            let fetchingMap = new Map(state.fetchingMap);
            fetchingMap.set(action.meta.DevID, false);
            try {
                let data = insertIntoData(state, action);
                return {
                    ...state,
                    fetchingMap,
                    data
                };
            } catch (e) {
                console.error(e);
                return {
                    ...state,
                    fetchingMap
                };
            }
        } catch (e) {
            console.error(e);
        }
    } else if (action.type === "FETCH_AGGREGATED_DATA_REJECTED") {
        let fetchingMap = new Map(state.fetchingMap);
        fetchingMap.set(action.meta.DevID, false);
        return {
            ...state,
            fetchingMap
        };
    } else if (action.type === "AGGREGATED_DATA_MQTT") {
        let data = insertIntoData(state, action);
        return {
            ...state,
            data
        }
    } else {
        return state
    }
}

