import {findIndex, get, isArray} from "lodash";

export const initialValue = {
    attributes: {},
    fetching: false,
    fetched: false,
    isAuthenticated: false,
    isLogIn: false,
    isAuthenticating: false,
    error: null,
    user: {},
    employees: [],
    allUsers: [],
    fetchingAllUsers: false,
    fetchedUsers: false,
    fetchingEmployees: false,
    updatingRoles: false,
    loadedData: false,
    isChangingClientID: false
};

export default function userReducer(state = initialValue, action) {

    switch (action.type) {
        case "FETCH_USER":
        case "USER_PASSWORD_CHANGE_PENDING":
        case "USER_LOGIN_PENDING": {
            return {...state, fetching: true, error: null}
        }
        case "USER_SET_ERROR":
        case "USER_TEMPORARY_PASSWORD_CHANGE_FAILED": {
            return {
                ...state,
                error: action.payload
            }
        }
        case "GET_CURRENT_USER_PENDING": {
            return {...state, isAuthenticating: true}
        }
        case "GET_CURRENT_USER_REJECTED": {
            return {...state, isAuthenticating: false}
        }
        case "GET_USER_FULFILLED": {
            return {...state, user: action.payload}
        }
        case "USER_IS_AUTHENTICATED": {
            return {
                ...state,
                isAuthenticated: true, isAuthenticating: false
            }
        }
        case "GET_USER_REJECTED": {
            return {...state, fetching: false, isAuthenticated: false, isAuthenticating: false, error: action.payload}
        }
        case "FETCH_USER_REJECTED": {
            return {...state, fetching: false, error: action.payload}
        }
        case "FETCH_USER_FULFILLED": {
            return {
                ...state,
                fetching: false,
                fetched: true,
                user: action.payload,
            }
        }
        case "FETCH_ALL_USERS_PENDING": {
            return {
                ...state,
                allUsers: [],
                fetchingAllUsers: true,
                fetchedUsers: false,
            }
        }
        case "FETCH_ALL_USERS_REJECTED": {
            return {
                ...state,
                fetchingAllUsers: false,
                fetchedUsers: false,
            }
        }
        case "FETCH_ALL_USERS_FULFILLED": {
            return {
                ...state,
                allUsers: (action.payload || []).slice(0),
                fetchingAllUsers: false,
                fetchedUsers: false,
            }
        }
        case "USER_CONFIRMATION_CODE_REQUEST_PENDING":
        case "USER_CONFIRMATION_ACCOUNT_PENDING":
        case "USER_FORGOTTEN_PASSWORD_REQUEST_PENDING":
        case "USER_RESET_ERROR_MESSAGE": {
            return {...state, error: undefined}
        }

        case "USER_CONFIRMATION_ACCOUNT_REJECTED":
        case "USER_CONFIRMATION_CODE_REQUEST_REJECTED": {
            let code = 2;
            if (action.payload && action.payload.code === "InvalidParameterException") code = 4;
            if (action.payload && action.payload.code === "LimitExceededException") code = 6;
            if (action.payload && action.payload.code === "CodeMismatchException") code = 7;
            return {...state, error: code}
        }

        // case "USER_AUTH": {
        //     return {...state, isAuthenticating: true}
        // }
        case "USER_AUTH_REJECTED": {
            return {...state, isAuthenticating: false, isAuthenticated: false, fetching: false, error: 1}
        }
        case "USER_LOGOUT_FULFILLED": {
            return {
                ...initialValue,
                isAuthenticating: false
            };
        }
        case "USER_LOGIN_REJECTED": {
            let code = 1;
            if (action.payload && action.payload === 11) code = 11;
            return {
                ...state,
                fetching: false,
                isAuthenticated: false,
                error: code
            }
        }
        case "USER_LOGIN_FULFILLED": {
            return {
                ...state,
                //fetching: false,
                fetched: !!action.payload,
                //isAuthenticated: true,
            }
        }
        case "USER_GET_ATTRIBUTES_REJECTED": {
            return {
                ...state,
                error: action.payload,
                isAuthenticating: false,
                fetching: false
            }
        }
        case "USER_GET_ATTRIBUTES_FULFILLED": {
            return {
                ...state,
                attributes: action.payload

            }
        }
        case "USER_TEMPORARY_PASSWORD_CHANGE_REQUEST": {
            return {
                ...state,
                userAttributes: action.payload.userAttributes,
                requiredAttributes: action.payload.requiredAttributes,
                loggedUser: action.payload.user,
                fetching: false
            }
        }
        case "USER_TEMPORARY_PASSWORD_CHANGE_COMPLETE": {
            return {
                ...state,
                userAttributes: null,
                requiredAttributes: null,
                loggedUser: null,
                isAuthenticated: false,
                error: null
            }
        }
        case "USER_PASSWORD_CHANGE_REJECTED": {
            return {
                ...state,
                error: action.payload,
                fetching: false
            }
        }
        case "USER_PASSWORD_CHANGE_FULFILLED": {
            return {
                ...state,
                fetching: false
            }
        }
        case "USER_FORGOTTEN_PASSWORD_REQUEST_FULFILLED": {
            return {
                ...state,
                email: action.payload
            }
        }
        case "USER_FORGOTTEN_PASSWORD_REQUEST_REJECTED": {
            let code = 3;
            if (action.payload && (action.payload.code === "CodeMismatchException")) {
                code = 10;
            }
            if (action.payload && (action.payload.code === "LimitExceededException")) {
                code = 6;
            }

            return {
                ...state,
                error: code,
            }
        }
        case "VERIFICATION_EMAIL_SEND":
        case "VERIFICATION_PHONE_SEND": {
            return {
                ...state,
                user: action.payload.user,
                verificationType: action.payload.type,
                verificationEmail: action.payload.email
            }
        }
        case "VERIFY_EMAIL_OR_PHONE_REJECTED": {
            return {
                ...state, error: action.payload.message
            }
        }
        case "GET_USER_EMPLOYEES_PENDING": {
            return {
                ...state, fetchingEmployees: true
            }
        }
        case "GET_USER_EMPLOYEES_REJECTED": {
            return {
                ...state, fetchingEmployees: false, employees: []
            }
        }
        case "GET_USER_EMPLOYEES_FULFILLED": {
            const employees = get(action, "payload.res", []).filter((e) => !e.DtaDelTime).slice(0);
            return {
                ...state, fetchingEmployees: false, employees
            }
        }

        case "USER_NEED_TO_ACCEPT_EULA": {
            return {
                ...state, fetching: false, isAuthenticating: false
            }
        }
        case "UPDATE_AUTHORIZATIONS_REJECTED":
        case "UPDATE_AUTHORIZATIONS_FULFILLED": {
            return {
                ...state, updatingRoles: false
            }
        }
        case "UPDATE_AUTHORIZATIONS_PENDING": {
            return {
                ...state, updatingRoles: true
            }
        }
        case "LOADED_DATA": {
            return {
                ...state, loadedData: true
            }
        }

        case "CHANGE_CLIENT_PENDING": {
            return {
                ...state,
                isChangingClientID: true
            }
        }
        case "CHANGE_CLIENT_REJECTED":
        case "REFRESH_TOKEN_REJECTED": {
            return {
                ...state,
                isChangingClientID: false
            }
        }
        case "REFRESH_TOKEN_FULFILLED": {
            return {
                ...state,
                isChangingClientID: false,
                user: {
                    ...state.user,
                    ClientID: action.meta.ClientID
                }
            }
        }

        case "USER_MAIL_VERIFY": {
            return {
                ...state,
                user: {...state.user, ClientID: action.meta.ClientID}
            }
        }

        case "USER_CHANGE_MQTT_PASSWORD": {
            return {
                ...state,
                user: {
                    ...state.user, LocalMqttSecret: action.payload
                }
            }
        }

        case "UPDATE_DOC_READ": {
            let doc = state.user.DocRead || [];
            return {
                ...state,
                user: {
                    ...state.user,
                    DocRead: [...doc, ...(isArray(action.payload) ? action.payload : [action.payload])]
                }
            }
        }
        case "UPDATE_LIC_PACKAGE": {
            const {user: {LicPackages}} = state;
            const copy = LicPackages.slice(0);
            let index = findIndex(copy, o => o.SubID === action.payload.SubID);
            copy[index] = action.payload;
            console.log(copy);
            return {
                ...state,
                user: {
                    ...state.user,
                    LicPackages: copy
                }
            }
        }
        case "CHANGE_USER_SECRET":
            return {
                ...state,
                user: {
                    ...state.user,
                    LocalStorageSecret: action.payload
                }
            }
        default:
            return state
    }
}
