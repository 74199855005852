export const initialValue = {
    documents: []
};

export default function documentsReducer(state = initialValue, action) {
    switch (action.type) {
        case "LIST_DOCUMENTS_FULFILLED": {
            return {
                ...state,
                documents: action.payload
            }
        }
        case "LIST_DOCUMENTS_PENDING": {
            return {
                ...state,
                documents: []
            }
        }
        // case "CHANGE_FARM":
        // case "USER_LOGOUT_FULFILLED": {
        //     return initialValue;
        // }
        default:
            return state
    }
}