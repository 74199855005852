const initialValue = {
    inMaintenance: false,
    currentMaintenance: undefined,
    futureMaintenance: undefined
};
export default function maintenanceReducer(state = initialValue, action) {
    switch (action.type) {
        case "MAINTENANCE_STATUS_PENDING":
            return {...state};
        case "MAINTENANCE_STATUS_FULFILLED":
            return {
                ...state,
                inMaintenance: action.payload.serviceInMaintenance,
                currentMaintenance: action.payload.inMaintenance,
                futureMaintenance: action.payload.futureMaintenance
            };
        case "MAINTENANCE_STATUS_REJECTED":
            return {
                ...state,
                inMaintenance: false,
                currentMaintenance: undefined,
                futureMaintenance: undefined,
                data: action.payload
            };
        case "USER_LOGOUT_FULFILLED": {
            return initialValue;
        }
        default:
            return state
    }
}
