const initialValue = {
    loading: false
};

export default function loadingReducer(state = initialValue, action) {
    switch (action.type) {
        case "START_LOADING":
            return {
                loading: true
            };
        case "END_LOADING":
        case "FETCH_FARM_FULFILLED":
            return {
                loading: false
            };
        case "CHANGE_FARM":
        case "USER_LOGOUT_FULFILLED": {
            return initialValue;
        }
        default:
            return state
    }
}
