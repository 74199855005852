export const initialValue = {
    tmpEvents: [],
    events: [],
    fetching: false,
    eventsFetched: false,
    eventsFetching: false,
    eventsForAnimals: new Map(),
    eventsForAnimalsLoading: new Map()
};

export default function eventsReducer(state = initialValue, action) {
    switch (action.type) {
        case "CREATE_EVENT": {
            return {...state, tmpEvents: action.payload.event}
        }
        case "CREATE_EVENT_PENDING": {
            return {...state, fetching: true}
        }
        case "CREATE_EVENT_REJECTED": {
            return {...state, error: action.payload.message, fetching: false}
        }
        case "CREATE_EVENT_FULFILLED": {
            return {
                ...state, fetching: false
            }
        }
        case "GET_ALL_EVENTS_FOR_FARM":
        case "GET_ALL_EVENTS": {
            return {...state, events: action.payload, eventsFetched: true, eventsFetching: false}
        }
        case "CREATE_AND_UPDATE_GROUP_PENDING":
            return {...state, fetching: true};
        case "CREATE_AND_UPDATE_GROUP_REJECTED":
            return {...state, fetching: false};
        case "CREATE_AND_UPDATE_GROUP_FULFILLED":
            return {...state, fetching: false};
        case "CHANGE_FARM":
        case "USER_LOGOUT_FULFILLED": {
            return initialValue
        }
        case "LIST_EVENT_4_ANIMAL_LOADING": {
            let eventsForAnimalsLoading = new Map(state.eventsForAnimalsLoading);
            eventsForAnimalsLoading.set(action.payload, true);
            return {
                ...state,
                eventsForAnimalsLoading
            }
        }
        case "LIST_EVENT_4_ANIMALS_LOADING": {
            let eventsForAnimalsLoading = new Map(state.eventsForAnimalsLoading);
            for (let id of action.payload) {
                eventsForAnimalsLoading.set(id, true);
            }
            return {
                ...state,
                eventsForAnimalsLoading
            }
        }
        case "LIST_EVENT_4_ANIMAL_REJECTED": {
            let eventsForAnimalsLoading = new Map(state.eventsForAnimalsLoading);
            eventsForAnimalsLoading.set(action.meta.AnmID, false);
            return {
                ...state,
                eventsForAnimalsLoading
            }
        }
        case "LIST_EVENT_4_ANIMAL_FULFILLED": {
            let eventsForAnimals = new Map(state.eventsForAnimals);
            eventsForAnimals.set(action.meta.AnmID, action.payload.items);
            let eventsForAnimalsLoading = new Map(state.eventsForAnimalsLoading);
            eventsForAnimalsLoading.set(action.meta.AnmID, false);
            return {
                ...state,
                eventsForAnimals,
                eventsForAnimalsLoading
            };
        }
        case "LIST_EVENTS_FOR_ANIMALS_REJECTED": {
            let eventsForAnimalsLoading = new Map(state.eventsForAnimalsLoading);
            for (let id of action.meta.AnmIDs) {
                eventsForAnimalsLoading.set(id, false);
            }
            return {
                ...state,
                eventsForAnimalsLoading
            }
        }
        case "LIST_EVENTS_FOR_ANIMALS_FULFILLED": {
            let eventsForAnimals = new Map(state.eventsForAnimals);
            let eventsForAnimalsLoading = new Map(state.eventsForAnimalsLoading);
            for (let id of action.meta.AnmIDs) {
                eventsForAnimals.set(id, action.payload[id]);
                eventsForAnimalsLoading.set(id, false);
            }
            return {
                ...state,
                eventsForAnimals,
                eventsForAnimalsLoading
            };
        }
        default:
            return state
    }
}
