import config from "../conf/config";
import store from "../store/store"
import {addNotification, removeNotifications} from 'reapop';
import {myID} from "./generateID";
import Auth from "@aws-amplify/auth"
import Paths from "../api/paths";
import ReactGA from "react-ga";
// import {redirect} from "../actions/historyActions";
import queryString from "querystring"
import apiDB from "../database/apiDB";
import i18next from "i18next";

Auth.configure({
    // Auth: {
    // REQUIRED - Amazon Cognito Identity Pool ID
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    // REQUIRED - Amazon Cognito Region
    region: config.cognito.REGION,
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: config.cognito.USER_POOL_ID,
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    /**
     // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
     mandatorySignIn: false,
     // OPTIONAL - Configuration for cookie storage
     cookieStorage: {
            // REQUIRED - Cookie domain (only required if cookieStorage is provided)
            domain: '.yourdomain.com',
            // OPTIONAL - Cookie path
            path: '/',
            // OPTIONAL - Cookie expiration in days
            expires: 365,
            // OPTIONAL - Cookie secure flag
            secure: true
        }
     */
    // }
});

export async function authUser() {
    try {
        Auth.currentSession()
            .then(data => console.log("authUser data", data))
            .catch(err => console.log(err));
        let user = await Auth.currentAuthenticatedUser();
        // nie wiem czy to jest potrzebne, wczesniej bylo sprawdzanie czy credentalsy sa aktualne, teraz odswiezam token
        Auth.currentUserCredentials().then(async credentials => {
            console.log("credentials", credentials);
            if (credentials.expired) {
                await refreshSession();
            }
        }).catch(e => {
            console.error("currentUserCredentialsError", e);
        });
        if (user) {
            return true;
        } else {
            Promise.reject(false);
        }
    } catch (e) {
        return Promise.reject(e);
    }
}

export async function getUserToken() {
    // console.log("session", await Auth.currentSession());
    return await Auth.currentSession().then(data => {
        // console.log("aaatttrr", data.idToken.jwtToken);
        return data.idToken.jwtToken
    })
        .catch(err => console.log(err));
    // return new Promise((resolve, reject) => {
    //     currentUser.getSession(async function (err, session) {
    //         if (err) {
    //             reject(err);
    //             return;
    //         }
    //         else if (session.isValid()) {
    //             ses = session;
    //             resolve(session.getIdToken().getJwtToken());
    //         }else{
    //
    //             const newSession = await new Promise((resolve, reject) => {
    //                 currentUser.refreshSession(session.getRefreshToken(), (err, result) => {
    //                     if (err) {
    //                         reject(err)
    //                     } else {
    //                         resolve(result)
    //                     }
    //                 })
    //             })
    //                 ses= await newSession;
    //             resolve(newSession.getIdToken().getJwtToken());
    //         }
    //     });
    // });
}

export async function getCurrentUser() {
    // const userPool = new CognitoUserPool({
    //     UserPoolId: config.cognito.USER_POOL_ID,
    //     ClientId: config.cognito.APP_CLIENT_ID
    // });
    // return userPool.getCurrentUser();
    try {
        return await Auth.currentAuthenticatedUser();
    } catch (e) {
        console.error(e);
        return null;
    }
}

export async function signOutUser() {
    try {
        await Auth.signOut();
    } catch (e) {
        console.error(e);
    }
    // store.dispatch(redirect("/"));
    store.dispatch(removeNotifications());
}

// function getAwsCredentials(userToken) {
//     const authenticator = `cognito-idp.${config.cognito
//         .REGION}.amazonaws.com/${config.cognito.USER_POOL_ID}`;
//
//     AWS.config.update({region: config.cognito.REGION});
//
//     AWS.config.credentials = new AWS.CognitoIdentityCredentials({
//         IdentityPoolId: config.cognito.IDENTITY_POOL_ID,
//         Logins: {
//             [authenticator]: userToken
//         }
//     });
//
//     return AWS.config.credentials.getpromise;
// }

export async function invokeApig({
                                     path,
                                     method = "GET",
                                     headers = {},
                                     queryParams = {},
                                     body,
                                     noAuthorization,
                                     signal
                                 }) {
    let token;
    if (!noAuthorization) {
        token = await getUserToken();
    }
    body = body ? JSON.stringify(body) : body;
    headers.authorization = noAuthorization ? "" : token;

    let query = queryString.stringify(queryParams);

    try {
        let startTime = new Date();
        const results = await fetch(config.apiGateway.URL + path + "?" + query, {
            // const results = await fetch(signedRequest.url, {
            method,
            headers,
            body,
            signal
        });
        let endTime = new Date();
        ReactGA.timing({
            category: "API calls",
            variable: results.url.split("?")[0],
            value: endTime - startTime,
        });
        if (results.status !== 200) {
            const r = await results.json();
            console.log(r);
            const backendMsg = r && r.statusText ? r.statusText : r.message ? r.message : "Nie rozpoznano";
            //console.log("results",r);
            if (results.status !== 422 && results.status !== 404) {
                let notifi = {
                    title: i18next.t("error"),
                    message: i18next.t("awsLib.errorNo", {
                        status: results.status,
                        message: i18next.t("awsLib.message"),
                        backendMsg: backendMsg
                    }),
                    status: 'error',
                    dismissible: true,
                    dismissAfter: 0
                };
                store.dispatch(addNotification(notifi));
            }
            return Promise.reject(r);
            //throw new Error(r.message);
        }
        // console.log('RESULT ivokeapig', results);
        return results.json();
    } catch (e) {
        console.error(e);
        console.error(e.name);
        if (e.name !== "AbortError") {
            // console.log("catch promise fetch",e);
            let notifi = {
                title: i18next.t("error"),
                message: i18next.t("awsLib.sync") + ` [${path} (${method})]`,
                status: 'info',
                dismissible: true,
                dismissAfter: 0
            };
            store.dispatch(addNotification(notifi));
            // nie zapisuj:
            // - zapytan typu GET
            // - zapytan z iot w sciezce
            // - gdy na widoku wprowadzania danych przez gridy
            if (method !== "GET" && !path.includes("iot") && !location.href.includes("eventChooser")) {
                apiDB.insert(path, method, headers, query, body);
            }
        }
        return Promise.reject("Failed to fetch");
    }
}

export async function createAndUpdateGroup(newAnimal, oldAnimal, event, clientID, localUserID) {
    try {
        //console.log(newAnimal);
        //dodanie nowego zwierzęcia
        await invokeApig({
            ...Paths.createAnimal({farmID: oldAnimal.FarmID, localUserID: localUserID, clientID: clientID}),
            body: newAnimal
        });
        //nadpisanie starego zwierzęcia
        await invokeApig({
            ...Paths.updateAnimal({farmID: oldAnimal.FarmID, localUserID: localUserID, clientID: clientID}),
            body: oldAnimal
        });
        //pobranie listy eventów starego zwierzęcia
        let eventsResponse = await invokeApig({
            ...Paths.listEvent({farmID: oldAnimal.FarmID}),
            queryParams: {FarmID: oldAnimal.FarmID, AnmID: oldAnimal.AnmID}
        });
        eventsResponse.map(e => {
            e.EvID = myID();
            e.AnmID = newAnimal.AnmID;
        });
        let events = eventsResponse.concat(event);
        let e = {
            events: events
        };
        //dodanie eventów dla nowego zwierzęcia
        await invokeApig({
            ...Paths.createEvent(),
            body: e
        });
    } catch (e) {
        //console.log("catch promise fetch",e);
        return Promise.reject("Failed to create or update")
    }
}


export async function createAndUpdateNotificattions(newAnimal, oldAnimal, event) {
    try {
        //console.log(newAnimal);
        //dodanie nowego zwierzęcia
        // let newAnimalRes = await invokeApig({
        //     path: "api/animal",
        //     method: "POST",
        //     body: newAnimal
        // });
        //nadpisanie starego zwierzęcia
        // let oldAnimalRes = await invokeApig({
        //     path: "api/animal/" + oldAnimal.FarmID,
        //     method: "PUT",
        //     body: oldAnimal
        // });
        //pobranie listy eventów starego zwierzęcia
        let eventsResponse = await invokeApig({
            ...Paths.listEvent({farmID: oldAnimal.FarmID}),
            method: "GET",
            queryParams: {FarmID: oldAnimal.FarmID, AnmID: oldAnimal.AnmID}
        });
        eventsResponse.map(e => {
            e.EvID = myID();
            e.AnmID = newAnimal.AnmID;
        });
        // let events = eventsResponse.concat(event);
        // let e = {
        //     events: events
        // };
        //dodanie eventów dla nowego zwierzęcia
        // let addEvents = await invokeApig({
        //     path: "api/events",
        //     method: "POST",
        //     body: e
        // })
    } catch (e) {
        //console.log("catch promise fetch",e);
        return Promise.reject("Failed to create or update")
    }
}

export function refreshSession() {
    return new Promise(async (resolve, reject) => {
        try {
            let session = await Auth.currentSession();
            let refreshToken = session.getRefreshToken();
            let user = await getCurrentUser();
            console.log(user, refreshToken);
            user.refreshSession(refreshToken, (err, data) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(data);
                }
            })
        } catch (e) {
            reject(e);
        }
    })
}

/*
export async function getContent(language = 'en') {
    return content.filter(obj => obj.lang === language)[0];
}*/
