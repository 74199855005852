import _ from "lodash";
import {FeedingType} from "../constans/feedingTypes";

export const initialValue = {
    resultsGridData: [],
    loadingResultsGrid: false,
    standingsMap: new Map(),
    feedingGrid: [],
    feedingMap: new Map(),
    dispensersSelected: new Map()
};

export default function gridReducer(state = initialValue, action) {
    switch (action.type) {
        case "SET_RESULTS_GRID_DATA_PENDING":
            return {...state, resultsGridData: [], loadingResultsGrid: true};
        case "SET_RESULTS_GRID_DATA_FULFILLED":
            return {...state, resultsGridData: action.payload, loadingResultsGrid: false};
        case "SAVE_CURRENT_PLAN":
            return {...state, resultsGridData: action.payload};
        case "CREATE_STANDINGS_GRID_FULFILLED": {
            let tmp = new Map(state.standingsMap);
            tmp.set(action.payload.location, action.payload.array);
            return {
                ...state, standingsMap: tmp
            }
        }
        case "FEEDING_SELECT_CLEAR":{
            return {...state, dispensersSelected: new Map()}
        }
        case "FEEDING_SELECT_STANDING_BY_CHAMBER": {
            const {location: {IndividualFeeding, CID}, nodes, select} = action.payload;
            let activeSelections = new Map();
            if (select) {
                let dispensers = nodes.map(data => {
                    const {BID, RFID} = data;
                    const id = IndividualFeeding ? BID : RFID;
                    return {
                        type: IndividualFeeding ? FeedingType.INDIVIDUAL : FeedingType.GROUP,
                        id: id,
                        locationID: CID
                    }
                });
                // activeSelections = new Map();
                activeSelections.set(CID, dispensers);
            } else {
                // activeSelections = new Map();
                // activeSelections.delete(CID);

            }
            return {...state, dispensersSelected: activeSelections}

        }
        case "FEEDING_SELECT_STANDING": {
            let activeSelections = new Map(state.dispensersSelected);
            let item = _.cloneDeep(action.payload);
            let selectedInLocation = _.cloneDeep(activeSelections.get(item.locationID) || []);
            let itemIndex = _.findIndex(selectedInLocation, (o) => _.isEqual(o, item));
            if (itemIndex > -1) {
                selectedInLocation.splice(itemIndex, 1);
            } else {
                selectedInLocation.push(item);
            }
            activeSelections = new Map();
            activeSelections.set(item.locationID, selectedInLocation);
            return {...state, dispensersSelected: activeSelections}
        }
        case "CREATE_FEEDING_GRID_FULFILLED": {
            let tmp = new Map(state.feedingMap);
            tmp.set(action.payload.location, action.payload.array);
            // let tmp = new Map();
            if (action.payload && action.payload.array) {
                if (tmp.get(action.payload.location) && tmp.get(action.payload.location).length !== action.payload.array.length)
                    return {
                        ...state,
                        feedingsSelected: [],
                        feedingGrid: action.payload.array,
                        feedingMap: tmp,
                        loadingFeedingGrid: false
                    }
            }
            return {
                ...state, feedingGrid: action.payload.array, feedingMap: tmp, loadingFeedingGrid: false

            }
        }
        case "CREATE_FEEDING_GRID_REJECTED": {
            return {
                ...state,
                feedingGrid: [],
                loadingFeedingGrid: false
            }
        }
        case "CREATE_FEEDING_GRID_PENDING": {
            return {
                ...state,
                feedingGrid: [],
                loadingFeedingGrid: true
            }
        }
        case "CHANGE_FARM":
        case "USER_LOGOUT_FULFILLED": {
            return initialValue;
        }
        default:
            return state;
    }
}
