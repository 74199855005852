import {get} from "lodash";

export const initialValue = {
    connecting: false,
    connected: false,
    status: new Map(),
    subscriptions: [],
    reconnecting: false
};

export default function mqttReducer(state = initialValue, action) {
    switch (action.type) {
        case "MQTT_CONNECT_PENDING":
            return {
                ...state,
                connecting: true,
                connected: false,
            };
        case "MQTT_CONNECT_FULFILLED":
            return {
                ...state,
                connecting: false,
                connected: true,
                reconnecting: false
            };
        case "MQTT_CONNECTION_FAILURE":
        case "MQTT_CONNECTION_LOST":
            return {
                ...state,
                connecting: false,
                connected: false,
            };
        case "USER_LOGOUT_FULFILLED": {
            return initialValue;
        }
        case "MQTT_CLEAR_STATUS":
        case "CHANGE_FARM": {
            return {
                ...state,
                status: new Map()
            }
        }
        case "MQTT_ON_PING_SEND": {
            let map = new Map(state.status);
            let GatewayID = action.payload;
            map.set(GatewayID, {send: +new Date()});
            return {
                ...state,
                status: map
            }
        }
        case "MQTT_ON_PING_SUCCESS": {
            let map = new Map(state.status);
            let GatewayID = action.payload.GatewayID;
            let sendTime = get(map.get(GatewayID), "send");
            if (sendTime) {
                map.set(GatewayID, {delay: +new Date() - sendTime, compilation: action.payload.compilation, version: action.payload.version, GatewayID});
            } else {
                map.set(GatewayID, {
                    delay: null,
                    compilation: action.payload.compilation,
                    version: action.payload.version,
                    GatewayID
                });
            }
            return {
                ...state,
                status: map
            }
        }
        case "MQTT_ON_PING_FAILURE": {
            let map = new Map(state.status);
            let GatewayID = action.payload;
            map.delete(GatewayID);
            return {
                ...state,
                status: map
            }
        }
        case "SUBSCRIBE_MQTT_TOPICS": {
            return {
                ...state,
                subscriptions: action.payload
            }
        }
        case "MQTT_KEEPS_RECONNECTING": {
            return {
                ...state,
                connecting: false,
                reconnecting: true
            }
        }
        default:
            return state
    }
}
