const initialValue = {
    fetchingSettlement: false,
    fetchedSettlement: false,
    settlement: []
};


export default function settlementReducer(state = initialValue, action) {
    switch (action.type) {
        case "LIST_CHAMBER_SETTLEMENTS_PENDING": {
            return {
                ...state,
                fetchingSettlement: true
            }
        }
        case "LIST_CHAMBER_SETTLEMENTS_REJECTED": {
            return {
                ...state,
                fetchingSettlement: false,
                fetchedSettlement: false,
                settlement: []
            }
        }
        case "LIST_CHAMBER_SETTLEMENTS_FULFILLED": {
            return {
                ...state,
                fetchingSettlement: false,
                fetchedSettlement: true,
                settlement: action.payload.slice(0)
            }
        }
        case "USER_LOGOUT":
        case "CHANGE_FARM":
            return initialValue;
        default:
            return state;
    }
}
