import {InventorySortType} from "../selectors/inventorySelector";

export const initialValue = {
    inventorySort: InventorySortType.BY_ANIMAL_NUMBER_DESC,
    loading: true,
    inventoryFilter: "",
    locationFilter: "",
    showDead: false,
    animals: [],
    groups: [],
    technoGroups: [],
    animalTypeMap: new Map(),
    size: 0,
    showCollapsed: false
};

export default function inventoryReducer(state = initialValue, action) {
    switch (action.type) {
        case "INVENTORY_INITIALIZE": {
            const {animals = [], groups = [], animalTypeMap = new Map(), technoGroups} = action.payload;
            return {...state, animals: animals, groups, loading: false, animalTypeMap, size: animals.length, technoGroups: technoGroups};
        }
        case "INVENTORY_FILTER": {
            const {inventoryFilter = ""} = action.payload;
            const tmp = inventoryFilter.trim() ? inventoryFilter.trim().split(" ") : null;
            return {...state, inventoryFilter: tmp}
        }
        case "INVENTORY_SORT": {
            const {inventorySort} = action.payload;
            return {...state, inventorySort}
        }
        case "INVENTORY_LOCATION_FILTER": {
            const {location} = action.payload;
            return {...state, locationFilter: location ? location : null}
        }
        case "INVENTORY_SHOW_DEAD": {
            const {showDead} = action.payload;
            return {...state, showDead: !!showDead}
        }
        case "INVENTORY_SHOW_COLLAPSED": {
            const {showCollapsed} = action.payload;
            return {...state, showCollapsed: !!showCollapsed}
        }
        case "CHANGE_FARM":
        case "USER_LOGOUT_FULFILLED": {
            return initialValue;
        }
        default:
            return state;
    }
}
