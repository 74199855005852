import {EventTypes} from "../constans/eventTypes";

const initialValue = {
    activeNipples: 0,
    fall: 0,
    fallPiglets: 0,
    grafting: 0,
    graftingPiglets: 0,
    heat: 0,
    insemination: 0,
    mommyAdd: 0,
    mommyGet: 0,
    noPregnancy: 0,
    parturition: 0,
    selection: 0,
    sell: 0,
    separationToPiglethouse: 0,
    transfer: 0,
    usg: 0,
    weighting: 0,
    castration: 0,
    toInsemination: 0,
    toRepeat: 0,
    reclassify: 0,
    insertion: 0,
    treatment: 0,
    history: []
};

export default function eventCounterReducer(state = initialValue, action) {
    switch (action.type) {
        case "ADD_ACTIVE_NIPPLES": {
            state.history.unshift({type: EventTypes.ACTIVE_NIPPLES, data: action.payload});
            return {...state, activeNipples: state.activeNipples + action.payload.length}
        }
        case "ADD_FALL": {
            state.history.unshift({type: EventTypes.FALL, data: action.payload});
            return {...state, fall: state.fall + action.payload.length}
        }
        case "ADD_FALL_PIGLETS": {
            state.history.unshift({type: EventTypes.FALL_PIGLETS, data: action.payload});
            return {...state, graftingPiglets: state.graftingPiglets + action.payload.length}
        }
        case "ADD_GRAFTING": {
            state.history.unshift({type: EventTypes.GRAFTING, data: action.payload});
            return {...state, grafting: state.grafting + action.payload.length}
        }
        case "ADD_GRAFTING_PIGLETS": {
            state.history.unshift({type: EventTypes.PIGLETS_TREATMENT, data: action.payload});
            return {...state, graftingPiglets: state.graftingPiglets + action.payload.length}
        }
        case "ADD_HEAT": {
            state.history.unshift({type: EventTypes.HEAT, data: action.payload});
            return {...state, heat: state.heat + action.payload.length}
        }
        case "ADD_INSEMINATION": {
            state.history.unshift({type: EventTypes.INSEMINATION, data: action.payload});
            return {...state, insemination: state.insemination + action.payload.length}
        }
        case "ADD_MOMMY_ADD": {
            state.history.unshift({type: EventTypes.MOMMY, data: action.payload});
            return {...state, mommyAdd: state.mommyAdd + action.payload.animals.length}
        }
        case "ADD_MOMMY_GET": {
            state.history.unshift({type: EventTypes.SEPARATION_TO_MOMMY, data: action.payload});
            return {...state, mommyGet: state.mommyGet + action.payload.length}
        }
        case "ADD_NO_PREGNANCY": {
            state.history.unshift({type: EventTypes.NO_PREGNANCY, data: action.payload});
            return {...state, noPregnancy: state.noPregnancy + action.payload.length}
        }
        case "ADD_PARTURITION": {
            state.history.unshift({type: EventTypes.PARTURITION, data: action.payload});
            return {...state, parturition: state.parturition + action.payload.length}
        }
        case "ADD_SELECTION": {
            state.history.unshift({type: EventTypes.SELECTION, data: action.payload});
            return {...state, selection: state.selection + action.payload.length}
        }
        case "ADD_SELL": {
            state.history.unshift({type: EventTypes.SELL, data: action.payload});
            return {...state, sell: state.sell + action.payload.length}
        }
        case "ADD_SEPARATION_TO_PIGLETHOUSE": {
            state.history.unshift({type: EventTypes.SEPARATION, data: action.payload});
            return {
                ...state,
                separationToPiglethouse: state.separationToPiglethouse + (action.payload.animals ? action.payload.animals.length : action.payload.length)
            }
        }
        case "ADD_TRANSFER": {
            state.history.unshift({type: EventTypes.TRANSFER, data: action.payload});
            return {...state, transfer: state.transfer + action.payload.length}
        }
        case "ADD_USG": {
            state.history.unshift({type: EventTypes.USG, data: action.payload});
            return {...state, usg: state.usg + action.payload.length}
        }
        case "ADD_WEIGHTING": {
            state.history.unshift({type: EventTypes.WEIGHTING, data: action.payload});
            return {...state, weighting: state.weighting + action.payload.length}
        }
        case "ADD_CASTRATION": {
            state.history.unshift({type: EventTypes.CASTRATION, data: action.payload});
            return {...state, castration: state.castration + action.payload.length}
        }
        case "ADD_TO_INSEMINATION": {
            state.history.unshift({type: EventTypes.TO_INSEMINATION, data: action.payload});
            return {...state, toInsemination: state.toInsemination + action.payload.length}
        }
        case "ADD_TO_REPEAT_INSEMINATION": {
            state.history.unshift({type: EventTypes.TO_REPEAT, data: action.payload});
            return {...state, toRepeat: state.toRepeat + action.payload.length}
        }
        case "ADD_RECLASSIFY": {
            state.history.unshift({type: EventTypes.RECLASSIFY, data: action.payload});
            return {...state, reclassify: state.reclassify + action.payload.length}
        }
        case "ADD_INSERTION": {
            state.history.unshift({type: "INSERTION", data: action.payload});
            return {...state, insertion: state.insertion + action.payload.length}
        }
        case "ADD_TREATMENT": {
            state.history.unshift({type: EventTypes.TREATMENT, data: action.payload});
            return {...state, treatment: state.treatment + action.payload[0].animals.length}
        }
        case "USER_LOGOUT":
        case "CHANGE_FARM":
            return initialValue;
        default:
            return state;
    }
}
