const initialValue = {
    editingTiles: new Map(),
    tilesToRestore: new Map(),
    updatedLayout: ""
};

export default function dashboardReducer(state = initialValue, action) {
    switch (action.type) {
        case "SET_EDITING_TILE": {
            let tmp = new Map(state.editingTiles);
            tmp.set(action.payload.i, action.payload); //payload: obiekt kafelka
            return {
                ...state, editingTiles: tmp
            }
        }
        case "STOP_TILE_EDITING": {
            let tmp = new Map(state.editingTiles);
            tmp.delete(action.payload); //payload: id kafelka (i)
            return {
                ...state, editingTiles: tmp
            }
        }
        case "ADD_TILE_TO_RESTORE": {
            let tmp = new Map(state.tilesToRestore);
            if (!tmp.has(action.payload.i)) {
                tmp.set(action.payload.i, action.payload);
            }
            return {
                ...state, tilesToRestore: tmp
            }
        }
        case "REMOVE_TILE_TO_RESTORE": {
            let tmp = new Map(state.tilesToRestore);
            tmp.delete(action.payload);
            return {
                ...state, tilesToRestore: tmp
            }
        }
        case "UPDATED_LAYOUT": {
            return {
                ...state, updatedLayout: action.payload
            }
        }
        case "CHANGE_FARM":
        case "USER_LOGOUT_FULFILLED": {
            return initialValue;
        }
        default:
            return state
    }
}
