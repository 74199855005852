import {get, maxBy} from "lodash";

const initialValue = {
    createDay: 0,
    technologyGroups: [],
    fetching: false,
    time: 0
};

const getTime = (technoGroups) => {
    return get(maxBy(technoGroups, o => o.DtaModTime), "DtaModTime", 0)
};

export default function technologyGroupReducer(state = initialValue, action) {
    switch (action.type) {
        case "CHANGE_CREATE_DAY":
            return {...state, createDay: action.payload};
        case "GET_CREATE_DAY":
            return {...state, createDay: action.payload};
        case "UPDATE_TECHNOLOGY_GROUP": {
            let tmp = Object.assign([], state.technologyGroups);
            tmp.map(i => {
                if (i.TGID === action.payload.TGID) {
                    Object.keys(action.payload).map(key => {
                        return i[key] = action.payload[key];
                    })
                }
                return i;
            });
            return {...state, technologyGroups: tmp, time: getTime(tmp)}
        }
        case "INSERT_TECHNOLOGY_GROUP": {
            let tmp = Object.assign([], state.technologyGroups);
            tmp.push(action.payload);
            return {...state, technologyGroups: tmp, time: getTime(tmp)}
        }
        case "GET_TECHNOLOGY_GROUPS":
            return {...state, technologyGroups: action.payload, time: getTime(action.payload), fetching: false};
        case "CLEAR_TECHNOLOGY_GROUPS":
            return {...state, technologyGroups: [], time: 0};
        case "CHANGE_FARM":
        case "USER_LOGOUT_FULFILLED": {
            return initialValue;
        }
        default:
            return state
    }
}
