import _ from "lodash";

export const initialValue = {
    devices: [],
    fetching: false,
    fetched: false,
    connected: false,
    connectedDevices: new Map(),
    pingedDevices: new Map(),
    currentDevice: undefined,
    devicesSearch: false,
    devicesSearchTimeout: undefined,
    devicesFound: [],
    startedAddressing: false,
    // piDelay: null,
    updateSend: new Map(),
    addressing: {
        address: 0,
        howManyAdressed: 0,
        toAddress: 0,
        protocol: ""
    }
};

export default function devicesReducer(state = initialValue, action) {
    switch (action.type) {
        case "GET_DEVICES":
            return {
                ...state,
                fetching: false,
                fetched: true,
                devices: action.payload
            };
        case "DEVICES_CONNECTED": {
            let tmp = new Map(state.connectedDevices);
            for (let [key, value] of action.payload.entries()) {
                tmp.set(key, value);
            }
            return {
                ...state, connectedDevices: tmp
            };
        }
        case "PING_DEVICE": {
            let tmp = new Map(state.pingedDevices);
            for (let [key, value] of action.payload.entries()) {
                tmp.set(key, value);
            }
            return {
                ...state, pingedDevices: tmp
            };
        }
        case "SET_CURRENT_DEVICE":
            return {
                ...state, currentDevice: action.payload
            };
        case "START_DEVICES_SEARCH":
            return {
                ...state, devicesSearch: true, devicesSearchTimeout: action.payload, devicesFound: []
            };
        case "STOP_DEVICES_SEARCH":
            if (state.devicesSearchTimeout) clearTimeout(state.devicesSearchTimeout);
            return {
                ...state,
                devicesSearch: false,
                devicesSearchTimeout: undefined,
                devicesFound: action.payload ? action.payload : []
            };
        case "CHANGE_ADDRESSING": {
            return {
                ...state,
                startedAddressing: action.payload.state,
                addressing: {
                    address: 0,
                    howManyAdressed: 0,
                    toAddress: action.payload.toAddress || 0,
                    protocol: action.payload.protocol || ""
                }
            }
        }
        case "STANDINGS_REFRESH_VALUES": {
            let tmp = _.cloneDeep(state.updateSend);
            tmp.set(action.payload.devID, action.payload);
            return {
                ...state, updateSend: tmp
            }
        }
        case "UPDATE_DEVICE_PENDING":
        case "ADD_DEVICE_TO_GATEWAY_PENDING":
        case "CREATE_DEVICE_PENDING": {
            return {
                ...state,
                fetching: true
            }
        }
        case "UPDATE_DEVICE_REJECTED":
        case "ADD_DEVICE_TO_GATEWAY_REJECTED":
        case "CREATE_DEVICE_REJECTED":
        case "CREATE_DEVICE_FULFILLED": {
            return {
                ...state,
                fetching: false
            }
        }
        case "CHANGE_ADDRESSING_STATE": {
            return {
                ...state,
                addressing: {
                    ...state.addressing,
                    ...action.payload
                }
            }
        }
        case "CHANGE_FARM":
        case "USER_LOGOUT_FULFILLED": {
            return initialValue;
        }
        default:
            return state
    }
}
