const initialValue = {
    fetching: false,
    fetched: false,
    error: null,
    endpointArn: null,
};

export default function snsReducer(state = initialValue, action) {
    switch (action.type) {
        case "REGISTER_SNS_ENDPOINT_FAILURE": {
            return {...state, fetching: false, error: action.payload, endpointArn: null}
        }
        case "REGISTER_SNS_ENDPOINT_FULLFILLED": {
            return {
                ...state,
                fetching: false,
                fetched: true,
                endpointArn: action.payload
            }
        }
        case "SUBSCRIBE_SNS_TOPIC_PENDING":
        case "UNSUBSCRIBE_SNS_TOPIC_PENDING": {
            return {...state, fetching: true, fetched: false}
        }
        case "SUBSCRIBE_SNS_TOPIC_REJECTED":
        case "UNSUBSCRIBE_SNS_TOPIC_REJECTED": {
            return {...state, fetching: false, error: action.payload}
        }
        case "SUBSCRIBE_SNS_TOPIC_FULLFILLED": {
            return {
                ...state,
                fetching: false,
                fetched: true
            }
        }
        case "UNSUBSCRIBE_SNS_TOPIC_FULLFILLED": {
            return {
                ...state,
                fetching: false,
                fetched: true,
                endpointArn: null
            }
        }
        case "USER_LOGOUT_FULFILLED": {
            return initialValue;
        }
        default:
            return state
    }
}
