const initialValue = {
    notifications: [],
    fetched: false,
    fetching: false,
    readTime: 0
};

export default function notificationReducer(state = initialValue, action) {
    switch (action.type) {
        case "GET_ALL_NOTIFICATIONS": {
            return {
                ...state,
                notifications: action.payload.data.slice(0).sort((o1, o2) => o2.DtaCrtTime - o1.DtaCrtTime),
                readTime: action.payload.notificationReadTime || 0
            }
        }
        case "LIST_NOTIFICATIONS_PENDING": {
            return {
                ...state,
                fetching: true
            }
        }

        case "SET_NOTIFICATIONS_READ_TIME": {
            return {
                ...state,
                readTime: action.payload
            }
        }
        case "LIST_NOTIFICATIONS_FULFILLED":
        case "LIST_NOTIFICATIONS_REJECTED": {
            return {
                ...state,
                fetched: action.type.endsWith("_FULFILLED")
            }
        }
        case "CHANGE_FARM":
        case "USER_LOGOUT_FULFILLED": {
            return initialValue;
        }
        default:
            return state
    }
}
