const initialValue = {
    boars: [],
    noPregnancyReasons: [],
    graftingReasons: [],
    medicines: [],
    fallReasons: [],
    clients: [],
    selectionReasons: [],
    weighting: []
};

function createArray(state, action, name) {
    let array = state[name].length === 3 ? state[name].slice(1) : state[name].slice(0);
    if (!array.includes(action.payload)) {
        array.push(action.payload);
    }
    return array;
}

export default function lastUsedReducer(state = initialValue, action) {
    switch (action.type) {
        case "SET_BOARS":
            return {
                ...state, boars: createArray(state, action, "boars")
            };
        case "SET_NO_PREGNANCY_REASON":
            return {
                ...state, noPregnancyReasons: createArray(state, action, "noPregnancyReasons")
            };
        case "SET_GRAFTING_REASON":
            return {
                ...state, graftingReasons: createArray(state, action, "graftingReasons")
            };
        case "SET_MEDICINE":
            return {
                ...state, medicines: createArray(state, action, "medicines")
            };
        case "SET_FALL_REASON":
            return {
                ...state, fallReasons: createArray(state, action, "fallReasons")
            };
        case "SET_CLIENT":
            return {
                ...state, clients: createArray(state, action, "clients")
            };
        case "SET_SELECTION_REASON":
            return {
                ...state, selectionReasons: createArray(state, action, "selectionReasons")
            };
        case "SET_WEIGHTING_REASON":
            return {
                ...state, weighting: createArray(state, action, "weighting")
            };
        case "CHANGE_FARM":
        case "USER_LOGOUT_FULFILLED": {
            return initialValue;
        }
        default:
            return state
    }
}
