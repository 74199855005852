export const initialValue = {
    viewProperties: {
        left: false,
        right: false,
        top: true
    },
    showBreeding: true,
    globalLoading: false
};

export default function viewPropertiesReducer(state = initialValue, action) {
    switch (action.type) {
        case "HIDE_NAVIGATION": {
            return {
                ...state,
                viewProperties: {
                    left: false,
                    right: false,
                    top: false
                }
            }
        }
        case "HIDE_SIDE_NAVIGATION": {
            return {
                ...state,
                viewProperties: {
                    left: false,
                    right: false,
                    top: true
                }
            }
        }
        case "SHOW_NAVIGATION": {
            return {
                ...state,
                viewProperties: {
                    left: true,
                    right: true,
                    top: true
                }
            }
        }
        case "SET_GLOBAL_LOADING": {
            return {
                ...state,
                globalLoading: action.payload
            }
        }
        case "MOBILE_SET_SHOW_BREEDING": {
            return {
                ...state,
                showBreeding: action.payload
            }
        }
        case "CHANGE_FARM":
        case "USER_LOGOUT_FULFILLED": {
            return initialValue;
        }
        default:
            return state
    }
}
