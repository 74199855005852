import {isArray, isEqual, uniqBy} from "lodash";

const initialValue = {
    logs: [],
    fetching: false,
    fetched: false,
    lastEvaluatedKey: null,
    objectId: null,
    startFrom: null,
    userId: null,
    search: "",
    searchCode: []
};

export default function logsReducer(state = initialValue, action) {

    switch (action.type) {
        case "LOGS_CHANGE_SEARCH": {
            return {
                ...state,
                search: action.payload
            }
        }
        case "LOGS_CHANGE_START_FROM": {
            const changed = state.startFrom !== action.payload;
            if (changed) {
                return {
                    ...state,
                    lastEvaluatedKey: null,
                    fetched: false,
                    fetching: true,
                    logs: [],
                    search: action.payload ? state.search : "",
                    startFrom: action.payload,
                }
            }
            return state;
        }
        case "LOGS_CHANGE_USER_ID": {
            const changed = state.userId !== action.payload;
            if (changed) {
                return {
                    ...state,
                    lastEvaluatedKey: null,
                    fetched: false,
                    fetching: true,
                    logs: [],
                    search: action.payload ? state.search : "",
                    userId: action.payload
                }
            }
            return state;

        }
        case "LOGS_CHANGE_CODE": {
            return {
                ...state,
                lastEvaluatedKey: null,
                fetched: false,
                fetching: true,
                logs: [],
                searchCode: action.payload ? [...new Set([...state.searchCode, action.payload])] : []
            }

        }
        case "LOGS_CHANGE_OBJECT_ID": {
            const changed = state.objectId !== action.payload;
            if (changed) {
                return {
                    ...state,
                    fetched: false,
                    fetching: true,
                    lastEvaluatedKey: null,
                    objectId: action.payload,
                    logs: [],
                    search: action.payload ? state.search : ""
                }
            }
            return state;


        }
        case "LIST_LOGS_PENDING": {
            const {meta} = action;
            // const invalid = (!isEqual(state.searchCode, meta.searchCode)) || (state.objectId !== meta.objectId) || (meta.userId !== state.userId) || (!state.lastEvaluatedKey && (meta.startFrom !== state.startFrom))
            // if (invalid) return state;
            return {
                ...state,
                lastEvaluatedKey: meta.lastEvaluatedKey ? state.lastEvaluatedKey : null,
                logs: state.logs,
                fetched: false,
                fetching: true
            }
        }
        case "LIST_LOGS_REJECTED": {
            const {meta} = action;
            const invalid = (!isEqual(state.searchCode, meta.searchCode)) || (state.objectId !== meta.objectId) || (meta.userId !== state.userId) || (!state.lastEvaluatedKey && (meta.startFrom !== state.startFrom))
            if (invalid) return state;
            return {
                ...state,
                fetching: false,
                fetched: false,
                lastEvaluatedKey: state.lastEvaluatedKey,
                logs: state.logs
            }
        }
        case "LIST_LOGS_FULFILLED": {
            const {meta} = action;
            const invalid = (!isEqual(state.searchCode, meta.searchCode)) || (state.objectId !== meta.objectId) || (meta.userId !== state.userId) || (!state.lastEvaluatedKey && (meta.startFrom !== state.startFrom))
            if (invalid) return state;
            const logsNew = uniqBy([...state.logs.slice(0), ...action.payload.items.slice(0).filter(e => e && e.TableName && isArray(e.Codes))], o => o.LogID).sort((o1, o2) => o2.DtaModTime - o1.DtaModTime)
            return {
                ...state,
                logs: logsNew,
                lastEvaluatedKey: action.payload.LastEvaluatedKey,
                fetched: true,
                fetching: false
            }
        }
        case "CLEAR_LOGS": {
            if (!!action.payload) {
                return {
                    ...initialValue,
                    logs: state.logs,
                    lastEvaluatedKey: state.lastEvaluatedKey
                }
            } else {
                return initialValue;
            }
    }
        case "LOGS_REMOVE_CODE": {
            return {
                ...state,
                lastEvaluatedKey: null,
                fetched: false,
                fetching: true,
                logs: [],
                searchCode: state.searchCode.filter(o => o !== action.payload)
            }

        }
        case "CHANGE_FARM":
        case "USER_LOGOUT_FULFILLED":
            return initialValue;
        default:
            return state
    }
}
