import _ from "lodash";

const _DEBUG = false;

const initialValue = {
    alarms: [],
    fetching: {},
    fetched: {}

};


export default function iotAlarmsReducer(state = initialValue, action) {
    switch (action.type) {
        case "IOT_ALARMS_SET_FETCHING": {
            const {gatewayId, fetching} = action.payload;
            const newFetching = {...state.fetching};
            const newFetched = {...state.fetched};
            newFetching[gatewayId] = fetching;
            newFetched[gatewayId] = false;
            return {
                ...state,
                fetching: newFetching,
                fetched: newFetched
            }
        }
        case "IOT_ALARMS_ADD_ALARMS": {
            const {gatewayId, alarms = []} = action.payload;
            _DEBUG && console.log("received new alarms on ", gatewayId, "alarms ", alarms);
            const newAlarms = [...state.alarms.filter(o => o._gw !== gatewayId), ...alarms.map((alarm) => ({
                alarms: alarm.alarms.sort((o1, o2) => o2.time - o1.time),
                _gw: gatewayId,
                DevID: alarm.DevID,
                Index: alarm.Index,
                newest: _.maxBy(alarm.alarms, (o) => o.time)
            }))];
            const newFetching = {...state.fetching};
            newFetching[gatewayId] = false;
            const newFetched = {...state.fetched};
            newFetched[gatewayId] = true;
            return {
                ...state,
                alarms: newAlarms,
                fetching: newFetching,
                fetched: newFetched

            };
        }
        case "IOT_ALARMS_RESET":
        case "CHANGE_FARM":
        case "USER_LOGOUT_FULFILLED": {
            return initialValue;
        }
        default:
            return state
    }
}
