const initialValue = {
    piDelay: null,
    piRequestSentTime: null,
    piRequestResponseTime: null,
    piTime: null
};

export default function timeReducer(state = initialValue, action) {
    switch (action.type) {
        case "GET_PI_TIME_REQUEST": {
            return {
                ...state,
                piRequestSentTime: +new Date()
            }
        }
        case "GET_PI_TIME_RESPONSE": {
            let responseTime = +new Date();
            let tmp = responseTime - (+action.payload) - (state.piRequestSentTime ? responseTime - state.piRequestSentTime : 0);
            return {
                ...state,
                piDelay: tmp,
                piRequestResponseTime: responseTime,
                piTime: +action.payload
            }
        }
        case "ZERO_PI_TIME":
            return initialValue;
        case "USER_LOGOUT_FULFILLED": {
            return initialValue;
        }
        default:
            return state
    }
}
