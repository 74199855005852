const initialValue = {
    offset: 0,
    listAmount: 100,
    allElements: 100,
    elementsOnPage: 100,
    totalPages: 0,
    currentPage: 0,
    displayRow: 100,
};

export default function footerReducer(state = initialValue, action) {
    switch (action.type) {
        case "NEXT_PAGE":
            return {...state, offset: action.payload};
        case "PREV_PAGE":
            return {...state, offset: action.payload};
        case "PAGE_INFO":
            return {...state, currentPage: action.payload.current, totalPages: action.payload.total};
        case "INIT_NOTIFICATION":
            return {...state, offset: 0, listAmount: 100, allElements: action.payload};
        case "GET_NOTIFICATION":
            return {...state, elementsOnPage: action.payload.length};
        case "SET_LIST_AMOUNT":
            if (parseInt(action.payload) < 0)
                action.payload = 100;
            if (parseInt(action.payload) >= state.allElements) {
                //console.log("if SET LIST AMOUNT", action.payload);
                return {...state, offset: 0, listAmount: parseInt(action.payload)}
            } else {
                //console.log("else SET LIST AMOUNT", action.payload);
                let tmp = parseInt(action.payload);
                if (action.payload === '')
                    tmp = 100;
                return {...state, listAmount: tmp}
            }

        case "SET_OFFSET":
            return {...state, offset: action.payload};
        case "CHANGE_FARM":
        case "USER_LOGOUT_FULFILLED": {
            return initialValue;
        }
        default:
            return state
    }
}
