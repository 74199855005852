import _ from "lodash";
import {removeDuplicateRFIDs} from "../utils/FeedingUtils";

const initialValue = {
    shadows: new Map(),
    animals: new Map(),
    loading: {}
};

export default function shadowReducer(state = initialValue, action) {
    switch (action.type) {
        case "SET_SHADOW_LOADING": {
            let newLoading = Object.assign({}, state.loading);
            const {DevID, status} = action.payload;
            const handle = (devId) => status === null ? (delete newLoading[devId]) : (newLoading[devId] = !!status)
            if (Array.isArray(DevID)) {
                DevID.forEach(d => {
                    handle(d);
                })
            } else {
                handle(DevID);
            }
            return {
                ...state,
                loading: newLoading
            }
        }
        case "CHANGE_SHADOWS_DELTA": {
            let map = new Map(state.shadows);
            for (let key of [...action.payload.keys()]) {
                //jesli ma caly obiekt to wrzuca jesli nie to nie zeby nie bylo niepelnych danych
                if (map.has(key)) {
                    let value = _.cloneDeep({...map.get(key), ...action.payload.get(key)});
                    map.set(key, value);
                }
            }
            return {
                ...state,
                shadows: map
            };
        }

        case "CHANGE_SHADOWS": {
            let merged = new Map([...state.shadows, ...action.payload]);
            return {
                ...state,
                shadows: merged
            };
        }

        case "CHANGE_SHADOWS_ANIMAL": {
            let merged = new Map([...state.animals, ...action.payload]);
            for (let key of action.payload.keys()) {
                merged = removeDuplicateRFIDs(merged, key);
            }
            return {
                ...state,
                animals: merged
            };
        }

        case "CHANGE_SHADOWS_ANIMAL_DELTA": {
            let map = new Map(state.animals);
            for (let key of [...action.payload.keys()]) {
                //jesli ma caly obiekt to wrzuca jesli nie to nie zeby nie bylo niepelnych danych
                if (map.has(key)) {
                    let RFIDs = action.payload.get(key).map(delta => {
                        return delta.RFID
                    });
                    let oldWithUpdated = map.get(key).filter(v => (!RFIDs.includes(v.RFID)));
                    // let value = [...map.get(key), ...action.payload.get(key)];
                    let value = [...oldWithUpdated, ...action.payload.get(key)];
                    map.set(key, value);
                }
                map = removeDuplicateRFIDs(map, key);
            }
            return {
                ...state,
                animals: map
            };
        }
        case "REMOVE_RFIDS_FROM_ANIMAL_SHADOW": {
            let map = new Map(state.animals);
            let RFIDs = action.payload;
            for (let [locID, rfs] of map.entries()) {
                map.set(locID, _.cloneDeep(rfs.filter(o => !RFIDs.includes(o.RFID))))
            }
            return {
                ...state,
                animals: map
            }

        }
        case "CHANGE_FARM":
        case "USER_LOGOUT_FULFILLED": {
            return initialValue;
        }
        default:
            return state
    }
}
