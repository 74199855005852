import {DICTIONARY_TYPE} from "../constans/general";
import _ from "lodash"

export const initialValue = {
    forageType: {
        Type: DICTIONARY_TYPE.forageType,
        WData: {
            pl: [],
            en: [],
            es: [],
            ru: []
        }
    },
    race: {
        Type: DICTIONARY_TYPE.race,
        WData: {
            pl: [],
            en: [],
            es: [],
            ru: []
        }
    },
    noPreganancy: {
        Type: DICTIONARY_TYPE.noPreganancy,
        WData: {
            pl: [],
            en: [],
            es: [],
            ru: []
        }
    },
    graftingReason: {
        Type: DICTIONARY_TYPE.graftingReason,
        WData: {
            pl: [],
            en: [],
            es: [],
            ru: []
        }
    },
    clients: {
        Type: DICTIONARY_TYPE.clients,
        WData: {
            pl: [],
            en: [],
            es: [],
            ru: []
        }
    },
    fallReasons: {
        Type: DICTIONARY_TYPE.fallReasons,
        WData: {
            pl: [],
            en: [],
            es: [],
            ru: []
        }
    },
    feedingCurves: {
        Type: DICTIONARY_TYPE.feedingCurves,
        WData: {
            pl: [],
            en: [],
            es: [],
            ru: []
        }
    },
    selectionReason: {
        Type: DICTIONARY_TYPE.selectionReason,
        WData: {
            pl: [],
            en: [],
            es: [],
            ru: []
        }
    },
    employees: {
        Type: DICTIONARY_TYPE.employees,
        WData: {
            pl: [],
            en: [],
            es: [],
            ru: []
        }
    },
    suppliers: {
        Type: DICTIONARY_TYPE.suppliers,
        WData: {
            pl: [],
            en: [],
            es: [],
            ru: []
        }
    },
    incidentAnimal: {
        Type: DICTIONARY_TYPE.incidentAnimal,
        WData: {
            pl: [],
            en: [],
            es: [],
            ru: []
        }
    },
    incidentPlace: {
        Type: DICTIONARY_TYPE.incidentPlace,
        WData: {
            pl: [],
            en: [],
            es: [],
            ru: []
        }
    },
    weighting: {
        Type: DICTIONARY_TYPE.weighting,
        WData: {
            pl: [],
            en: [],
            es: [],
            ru: []
        }
    },
    taskCategory: {
        Type: DICTIONARY_TYPE.taskCategory,
        WData: {
            pl: [],
            en: [],
            es: [],
            ru: []
        }
    },
    fetching: false,
    controlLists: [],
    medicine: [],
    graftingPrograms: [],
    forageIngredients: []
};

// function removeInactive(data){
//     let p = JSON.parse(JSON.stringify(data));
//     let pK = Object.keys(p.WData);
//     pK.map(key => {
//         let i = p.WData[key].length;
//         while (i--) {
//             if (!p.WData[key][i].isShowing) {
//                 p.WData[key].splice(i, 1)
//             }
//         }
//     });
//     delete p.$loki;
//     delete p.meta;
//     return p;
// }

function combineDictionaries(dictionary, defaultDictionary) {
    let tmpNotDefault = _.cloneDeep(dictionary);
    let tmpDefault = defaultDictionary ? _.cloneDeep(defaultDictionary) : {};
    if (tmpDefault && tmpDefault.WData) {
        Object.keys(tmpDefault.WData).map(key => {
            if (!tmpNotDefault.WData[key]) {
                tmpNotDefault.WData[key] = tmpDefault.WData[key].map(d => {
                    d.isDefault = true;
                    return d;
                })
            } else {
                tmpNotDefault.WData[key] = tmpNotDefault.WData[key].concat(tmpDefault.WData[key].map(d => {
                    d.isDefault = true;
                    return d
                }));
            }
            return key;
        })
    }
    return tmpNotDefault;
}

function combineControlLists(dictionary, defaultDictionary) {
    let cp = dictionary.slice(0);
    let lists = [];
    for (let defDictionary of defaultDictionary) {
        let hasNotDefault = dictionary.find(item => item.WordID === defDictionary.WordID);
        if (hasNotDefault) {
            cp = cp.filter(item => item.WordID !== hasNotDefault.WordID);
        }
        lists.push(hasNotDefault || defDictionary);
    }
    lists = [...lists, ...cp.map(item => item)];
    console.log(lists);
    return lists;
}

export default function dictionaryReducer(state = initialValue, action) {
    switch (action.type) {
        case "GET_ALL_DICTIONARIES":
            let pNotDefault = action.payload.filter(item => item.Type === DICTIONARY_TYPE.forageType && item.ClientID !== "Default")[0];
            if (!pNotDefault) pNotDefault = {
                Type: DICTIONARY_TYPE.forageType,
                WData: {
                    pl: [],
                    en: [],
                    ru: [],
                    es: []
                }
            };
            // console.log(pNotDefault);
            let pDefault = action.payload.filter(item => item.Type === DICTIONARY_TYPE.forageType && item.ClientID === "Default")[0];
            let p = combineDictionaries(pNotDefault, pDefault);
            // console.log(p);
            let cNotDefault = action.payload.filter(item => item.Type === DICTIONARY_TYPE.clients && item.ClientID !== "Default")[0];
            if (!cNotDefault) cNotDefault = {
                Type: DICTIONARY_TYPE.clients,
                WData: {
                    pl: [],
                    en: [],
                    ru: [],
                    es: []
                }
            };
            let cDefault = action.payload.filter(item => item.Type === DICTIONARY_TYPE.clients && item.ClientID === "Default")[0];
            let c = combineDictionaries(cNotDefault, cDefault);

            let fNotDefault = action.payload.filter(item => item.Type === DICTIONARY_TYPE.fallReasons && item.ClientID !== "Default")[0];
            if (!fNotDefault) fNotDefault = {
                Type: DICTIONARY_TYPE.fallReasons,
                WData: {
                    pl: [],
                    en: [],
                    ru: [],
                    es: []
                }
            };
            let fDefault = action.payload.filter(item => item.Type === DICTIONARY_TYPE.fallReasons && item.ClientID === "Default")[0];
            let f = combineDictionaries(fNotDefault, fDefault);

            // let mNotDefault = action.payload.filter(item => item.Type === DICTIONARY_TYPE.medicine && item.ClientID !== "Default")[0];
            // if (!mNotDefault) mNotDefault = {
            //     Type: DICTIONARY_TYPE.medicine,
            //     WData: {
            //         pl: [],
            //         en: [],
            //         ru: [],
            //         es: []
            //     }
            // };
            // let mDefault = action.payload.filter(item => item.Type === DICTIONARY_TYPE.medicine && item.ClientID === "Default")[0];
            // let m = combineDictionaries(mNotDefault, mDefault);

            let nNotDefault = action.payload.filter(item => item.Type === DICTIONARY_TYPE.noPreganancy && item.ClientID !== "Default")[0];
            if (!nNotDefault) nNotDefault = {
                Type: DICTIONARY_TYPE.noPreganancy,
                WData: {
                    pl: [],
                    en: [],
                    ru: [],
                    es: []
                }
            };
            let nDefault = action.payload.filter(item => item.Type === DICTIONARY_TYPE.noPreganancy && item.ClientID === "Default")[0];
            let n = combineDictionaries(nNotDefault, nDefault);

            let gNotDefault = action.payload.filter(item => item.Type === DICTIONARY_TYPE.graftingReason && item.ClientID !== "Default")[0];
            if (!gNotDefault) gNotDefault = {
                Type: DICTIONARY_TYPE.graftingReason,
                WData: {
                    pl: [],
                    en: [],
                    ru: [],
                    es: []
                }
            };
            let gDefault = action.payload.filter(item => item.Type === DICTIONARY_TYPE.graftingReason && item.ClientID === "Default")[0];
            let g = combineDictionaries(gNotDefault, gDefault);

            let rNotDefault = action.payload.filter(item => item.Type === DICTIONARY_TYPE.race && item.ClientID !== "Default")[0];
            if (!rNotDefault) rNotDefault = {
                Type: DICTIONARY_TYPE.race,
                WData: {
                    pl: [],
                    en: [],
                    ru: [],
                    es: []
                }
            };
            let rDefault = action.payload.filter(item => item.Type === DICTIONARY_TYPE.race && item.ClientID === "Default")[0];
            let r = combineDictionaries(rNotDefault, rDefault);

            let uNotDefault = action.payload.filter(item => item.Type === DICTIONARY_TYPE.feedingCurves && item.ClientID !== "Default")[0];
            if (!uNotDefault) uNotDefault = {
                Type: DICTIONARY_TYPE.feedingCurves,
                WData: {
                    pl: [],
                    en: [],
                    ru: [],
                    es: []
                }
            };
            let uDefault = action.payload.filter(item => item.Type === DICTIONARY_TYPE.feedingCurves && item.ClientID === "Default")[0];
            let u = combineDictionaries(uNotDefault, uDefault);

            let sNotDefault = action.payload.filter(item => item.Type === DICTIONARY_TYPE.selectionReason && item.ClientID !== "Default")[0];
            if (!sNotDefault) sNotDefault = {
                Type: DICTIONARY_TYPE.selectionReason,
                WData: {
                    pl: [],
                    en: [],
                    ru: [],
                    es: []
                }
            };
            let sDefault = action.payload.filter(item => item.Type === DICTIONARY_TYPE.selectionReason && item.ClientID === "Default")[0];
            let s = combineDictionaries(sNotDefault, sDefault);

            let eNotDefault = action.payload.filter(item => item.Type === DICTIONARY_TYPE.employees && item.ClientID !== "Default")[0];
            if (!eNotDefault) eNotDefault = {
                Type: DICTIONARY_TYPE.employees,
                WData: {
                    pl: [],
                    en: [],
                    ru: [],
                    es: []
                }
            };
            let eDefault = action.payload.filter(item => item.Type === DICTIONARY_TYPE.employees && item.ClientID === "Default")[0];
            let e = combineDictionaries(eNotDefault, eDefault);

            let lNotDefault = action.payload.filter(item => item.Type === DICTIONARY_TYPE.suppliers && item.ClientID !== "Default")[0];
            if (!lNotDefault) lNotDefault = {
                Type: DICTIONARY_TYPE.suppliers,
                WData: {
                    pl: [],
                    en: [],
                    ru: [],
                    es: []
                }
            };
            let lDefault = action.payload.filter(item => item.Type === DICTIONARY_TYPE.suppliers && item.ClientID === "Default")[0];
            let l = combineDictionaries(lNotDefault, lDefault);

            let iaNotDefault = action.payload.filter(item => item.Type === DICTIONARY_TYPE.incidentAnimal && item.ClientID !== "Default")[0];
            if (!iaNotDefault) iaNotDefault = {
                Type: DICTIONARY_TYPE.incidentAnimal,
                WData: {
                    pl: [],
                    en: [],
                    ru: [],
                    es: []
                }
            };
            let iaDefault = action.payload.filter(item => item.Type === DICTIONARY_TYPE.incidentAnimal && item.ClientID === "Default")[0];
            let ia = combineDictionaries(iaNotDefault, iaDefault);

            let ipNotDefault = action.payload.filter(item => item.Type === DICTIONARY_TYPE.incidentPlace && item.ClientID !== "Default")[0];
            if (!ipNotDefault) ipNotDefault = {
                Type: DICTIONARY_TYPE.incidentPlace,
                WData: {
                    pl: [],
                    en: [],
                    ru: [],
                    es: []
                }
            };
            let ipDefault = action.payload.filter(item => item.Type === DICTIONARY_TYPE.incidentPlace && item.ClientID === "Default")[0];
            let ip = combineDictionaries(ipNotDefault, ipDefault);

            let wNotDefault = action.payload.filter(item => item.Type === DICTIONARY_TYPE.weighting && item.ClientID !== "Default")[0];
            if (!wNotDefault) wNotDefault = {
                Type: DICTIONARY_TYPE.weighting,
                WData: {
                    pl: [],
                    en: [],
                    ru: [],
                    es: []
                }
            };
            let wDefault = action.payload.filter(item => item.Type === DICTIONARY_TYPE.weighting && item.ClientID === "Default")[0];
            let w = combineDictionaries(wNotDefault, wDefault);

            let tcNotDefault = action.payload.filter(item => item.Type === DICTIONARY_TYPE.taskCategory && item.ClientID !== "Default")[0];
            if (!tcNotDefault) tcNotDefault = {
                Type: DICTIONARY_TYPE.taskCategory,
                WData: {
                    pl: [],
                    en: [],
                    ru: [],
                    es: []
                }
            };
            let tcDefault = action.payload.filter(item => item.Type === DICTIONARY_TYPE.taskCategory && item.ClientID === "Default")[0];
            let tc = combineDictionaries(tcNotDefault, tcDefault);

            let defaultControlList = action.payload.filter(item => item.Type === DICTIONARY_TYPE.controlLists && item.ClientID === "Default");
            let controlList = action.payload.filter(item => item.Type === DICTIONARY_TYPE.controlLists && item.ClientID !== "Default");

            let defaultMedicines = action.payload.filter(item => item.Type === DICTIONARY_TYPE.medicine && item.ClientID === "Default");
            let medicines = action.payload.filter(item => item.Type === DICTIONARY_TYPE.medicine && item.ClientID !== "Default");

            let defaultGraftingPrograms = action.payload.filter(item => item.Type === DICTIONARY_TYPE.graftingProgram && item.ClientID === "Default");
            let graftingPrograms = action.payload.filter(item => item.Type === DICTIONARY_TYPE.graftingProgram && item.ClientID !== "Default");
            let forageIngredients = action.payload.filter(item => item.Type === DICTIONARY_TYPE.forageIngredient && item.ClientID !== "Default");
            return {
                ...state,
                selectionReason: s,
                forageType: p,
                clients: c,
                fallReasons: f,
                medicine: combineControlLists(medicines, defaultMedicines),
                noPreganancy: n,
                graftingReason: g,
                race: r,
                feedingCurves: u,
                employees: e,
                suppliers: l,
                incidentAnimal: ia,
                incidentPlace: ip,
                weighting: w,
                controlLists: combineControlLists(controlList, defaultControlList),
                graftingPrograms: combineControlLists(graftingPrograms, defaultGraftingPrograms),
                forageIngredients: forageIngredients,
                taskCategory: tc
            };
        case "LIST_DICTIONARY_PENDING":
            return {...state, fetching: true};
        case "LIST_DICTIONARY_FULFILLED":
            return {...state, fetching: false};
        case "LIST_DICTIONARY_REJECTED":
            return {...state, fetching: false};
        case "LIST_DEFAULT_DICTIONARY_PENDING":
            return {...state, fetching: true};
        case "LIST_DEFAULT_DICTIONARY_FULFILLED":
            return {...state, fetching: false};
        case "LIST_DEFAULT_DICTIONARY_REJECTED":
            return {...state, fetching: false};
        case "CREATE_DEVICE_PENDING":
            return {...state, fetching: true};
        case "CREATE_DEVICE_REJECTED":
        case "CREATE_DEVICE_FULFILLED":
            return {...state, fetching: false};
        case "CLEAR_DICTIONARY":
            return initialValue;
        case "USER_LOGOUT_FULFILLED": {
            return initialValue;
        }
        case "UPDATE_DICTIONARY_PENDING":
            return {...state, fetching: true};
        case "UPDATE_DICTIONARY_REJECTED":
            return {...state, fetching: false};
        default:
            return state
    }
}
