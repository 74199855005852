import {TaskStatus} from "../constans/task";
import moment from "moment";

const initialValue = {
    tasks: [], // lista zadań
    filters: {Status: {$or: [TaskStatus.OPENED]}}, // zaznaczone tylko zadania do wykonania
    loading: false, // czy pobierane są taski
    date: moment().format(moment.HTML5_FMT.DATE), // wybrana data
    selectedTask: null, // wybrany task
};

export default function timeReducer(state = initialValue, action) {
    switch (action.type) {
        case "SET_FILTERS":
            return {...state, filters: action.payload}
        case "GET_TASKS_PENDING":
            return {...state, loading: true}
        case "GET_TASKS_FULFILLED":
            let selectedTask = null;
            if (state.selectedTask) {
                selectedTask = action.payload.find(item => item.IssueID === state.selectedTask.IssueID);
                if (!selectedTask) {
                    selectedTask = state.selectedTask;
                }
            }
            return {...state, tasks: action.payload, loading: false, selectedTask}
        case "GET_TASKS_REJECTED":
            return {...state, tasks: [], loading: false}
        case "CHANGE_DATE":
            return {...state, date: action.payload}
        case "SET_SELECTED_TASK":
            return {...state, selectedTask: action.payload}
        case "CHANGE_FARM":
        default:
            return state
    }
}
