export const initialValue = {
    expectingBirth: [],
    fetchExpectectingBirth: false,
    feedConsumptionDispensers: [],
    feedConsumptionChambers: [],
    fetchFeedConsumption: false,
    idleDays: [],
    fetchIdleDays: false,
    inseminations: [],
    fetchInseminations: false,
    herdStructure: [],
    fetchHerdStructure: false,
    sales: [],
    fetchSales: false,
    treatment: [],
    fetchTreatment: false,
    separation: [],
    fetchSeparation: false,
    medicineConsumption: [],
    fetchMedicineConsumption: false,
    dispenserUsage: [],
    dispenserBuildingsUsage: [],
    dispenserSectorsUsage: [],
    dispenserChambersUsage: [],
    fetchDispenserUsage: false,
    reports: []
};

export default function raportsReducer(state = initialValue, action) {
    switch (action.type) {
        case "PREPARE_RAPORT_DATA_HISTORY_BIRTH":
            return {...state, Birth: action.payload, fetchBirth: true};

        case "PREPARE_RAPORT_DATA_FEED_CONSUMPTION":
            return {
                ...state,
                feedConsumptionDispensers: action.payload[0],
                feedConsumptionChambers: action.payload[1],
                fetchFeedConsumption: true
            };
        case "PREPARE_RAPORT_DATA_DISPENSER_USAGE":
            return {
                ...state,
                dispenserUsage: action.payload[0],
                dispenserChambersUsage: action.payload[1],
                dispenserSectorsUsage: action.payload[2],
                dispenserBuildingsUsage: action.payload[3],
                fetchDispenserUsage: true
            };
        case "PREPARE_RAPORT_DATA_MEDICINE_CONSUMPTION":
            return {
                ...state,
                medicineConsumption: action.payload,
                fetchMedicineConsumption: true
            };
        case "PREPARE_RAPORT_DATA_FEED_DISPENSER":
            console.log("Weszło :(");
            return {
                ...state,
                feedDispensers: action.payload,
                fetchFeedDispensers: true
            };
        case "PREPARE_RAPORT_DATA_INSEMINATIONS":
            return {
                ...state,
                inseminations: action.payload,
                fetchInseminations: true
            };
        case "PREPARE_RAPORT_DATA_HERD_STRUCTURE":
            return {
                ...state,
                herdStructure: action.payload,
                fetchHerdStructure: true
            };
        case "PREPARE_RAPORT_DATA_SALES":
            return {
                ...state,
                sales: action.payload,
                fetchSales: true
            };
        case "PREPARE_RAPORT_DATA_TREATMENT":
            return {
                ...state,
                treatment: action.payload,
                fetchTreatment: true
            };
        case "PREPARE_RAPORT_DATA_SEPARATION":
            return {
                ...state,
                separation: action.payload,
                fetchSeparation: true
            };
        case "PREPARE_RAPORT_DATA_IDLE_DAYS":
            return {...state, idleDays: action.payload, fetchIdleDays: true};


        case "CHANGE_FARM":
        case "USER_LOGOUT_FULFILLED": {
            return initialValue;
        }
        case "GET_ALL_REPORTS": {
            return {
                ...state,
                reports: action.payload
            }
        }
        default:
            return state
    }
}
