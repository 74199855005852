const initialValue = {
    fetched: false,
    fetching: false,
    newsValues: []
};

export default function newsReducer(state = initialValue, action) {
    switch (action.type) {
        case "CREATE_NEWS_PENDING":
        case "GET_NEWS_PENDING": {
            return {...state, fetching: true, fetched: false}
        }
        case "CREATE_NEWS_REJECTED":
        case "GET_NEWS_REJECTED": {
            return {...state, fetching: false, error: action.payload}
        }
        case "CREATE_NEWS_FULFILLED": {
            return {
                ...state,
                fetching: false,
                fetched: true,
            }
        }
        case "GET_NEWS_FULFILLED": {
            const store = require("../store/store").default;
            let news = action.payload.filter(e => e.Lang === store.getState().language.lang.lang);
            return {
                ...state,
                fetching: false,
                fetched: true,
                newsValues: news
            }
        }
        case "USER_LOGOUT_FULFILLED": {
            return initialValue;
        }
        default:
            return state
    }
}
