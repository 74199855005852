export const initialValue = {
    lang: {},
    langDynamic: [],
    supportedLanguages: [],
    locale: "pl",
    fetching: false,
    fetched: false,
    error: null,
    farmTreeDisplay: false,
    translations: {
        content: {},
        acceptance: {}
    },
    availableTranslationLanguages: [],
    loading: {
        translationLanguages: false,
        translations: false
    }
};

export default function localizationReducer(state = initialValue, action) {

    switch (action.type) {
        case "FETCH_LANGUAGE": {
            return {...state, fetching: true}
        }
        case "FETCH_LANGUAGE_REJECTED": {
            return {...state, fetching: false, error: action.payload}
        }
        case "FETCH_LANGUAGE_FULFILLED": {
            return {
                ...state,
                fetching: false,
                fetched: true,
                lang: action.payload,
            }
        }
        case "SET_LANGUAGE": {
            return {
                ...state,
                locale: action.payload,
                lang: {...state.lang, lang: action.payload}
            }
        }
        case "GET_LANGUAGE_CONTENT_FULFILLED": {
            return {...state, lang: action.payload,}
        }
        case "GET_LANGUAGE_CONTENT_DYNAMIC": {
            return {...state, langDynamic: action.payload,}
        }
        case "GET_LANGUAGES": {
            return {...state, supportedLanguages: action.payload,}
        }
        case "GET_TRANSLATION_PENDING": {
            return {...state, loading: {...state.loading, translations: action.meta}}
        }
        case "GET_TRANSLATION_REJECTED": {
            return {...state, loading: {...state.loading, translations: false}}
        }
        case "GET_TRANSLATION_FULFILLED": {
            return {
                ...state,
                translations: {
                    content: {
                        ...state.translations.content,
                        ...action.payload.content
                    },
                    acceptance: {
                        ...state.translations.acceptance,
                        ...action.payload.acceptance
                    }
                },
                loading: {...state.loading, translations: false}
            }
        }
        case "GET_ALL_TRANSLATION_LANGUAGES_PENDING": {
            return {...state, loading: {...state.loading, translationLanguages: true}}
        }
        case "GET_ALL_TRANSLATION_LANGUAGES_REJECTED": {
            return {...state, loading: {...state.loading, translationLanguages: false}}
        }
        case "GET_ALL_TRANSLATION_LANGUAGES_FULFILLED": {
            return {
                ...state,
                availableTranslationLanguages: action.payload,
                loading: {...state.loading, translationLanguages: false}
            }
        }
        case "ADD_LANGUAGE": {
            const availableTranslationLanguages = state.availableTranslationLanguages.slice(0);
            availableTranslationLanguages.push(action.payload);
            return {...state, availableTranslationLanguages}
        }
        case "CHANGE_TRANSLATION": {
            return {
                ...state,
                translations: {
                    ...state.translations,
                    [action.meta]: action.payload
                }
            }
        }
        default:
            return state
    }
}
