import {
    ClimateDriverCommandTypes,
    DispenserDriverCommandTypes,
    DispenserNRFCommandTypes,
    GatawayCommandTypes,
    ScaleHubCommandTypes,
    SeparationCageCommandTypes
} from "../constans/mqttMessages";

const messageParse = {
    SET_CONFIGURATION: {
        keys: ["maxSingularDose", "timeBetweenDoses", "autoFinish", "globalLock", "maxCurrentFluctuation",
            "maxOverfluctuationTime", "overloadDetection", "flashDisplay", "isPin", "minWeights", "pin", "sweepWeights",
            "additionalHeatingVentilation", "alarms", "bandWidth", "coolingConfiguration", "floors", "heatingBreakTime",
            "heatingDeviation", "heatingWorkTime", "isActive", "manual", "maximumHoldFor20Percent", "requestedAirTemperature",
            "requestedTemperatureDeviation", "softwareVersion", "ventilationConfiguration"]
    },
    SET_WORK_TYPE: {
        keys: ["workType", "initDose", "maxOneDose", "timeOrImpulse", "optButt"]
    },
    [SeparationCageCommandTypes.SET_ATTEMPTS_NUMBER]: {
        keys: ["attemptsNumber"]
    },
    [SeparationCageCommandTypes.SET_AVERAGE_WEIGHT_YESTERDAY]: {
        keys: ["yesterday"]
    },
    [SeparationCageCommandTypes.SET_DEFAULT_EXIT]: {
        keys: ["defaultExit"]
    },
    [SeparationCageCommandTypes.SET_EXIT_TIME]: {
        keys: ["exitTime"]
    },
    [SeparationCageCommandTypes.SET_NUMBERS_SEND_TO_EXITS]: {
        keys: ["exit1", "exit3"]
    },
    [SeparationCageCommandTypes.SET_RETURN_TO_DEFAULT_EXITL]: {
        keys: ["returnToDefaultExit"]
    },
    [SeparationCageCommandTypes.SET_TARE_STATUS]: {
        keys: ["tareStatus"]
    },
    [SeparationCageCommandTypes.SET_SCALE_FACTOR]: {
        keys: ["SW", "SD"]
    },
    [SeparationCageCommandTypes.SET_WORK_TYPE_DURATION]: {
        keys: ["training1", "training2", "training3", "separation", "expedition"]
    },
    [SeparationCageCommandTypes.SET_SEPARATION_WEIGHT_RANGE]: {
        keys: ["minWeight", "maxWeight"]
    },
    [SeparationCageCommandTypes.SET_SEPARATION_ANIMAL_COUNT]: {
        keys: ["animalsNumber"]
    },
    [SeparationCageCommandTypes.SET_EXPEDITION_DATA]: {
        keys: ["animalsNumber", "minWeight", "maxWeight", "keepCounter", "workType"]
    },
    [DispenserNRFCommandTypes.SET_MOTOR_SETUP]: {
        keys: ["currentOverloadTime", "timeAbsent", "timeBetweenImpulses", "timeBreak", "timeOn"]
    },
    [ScaleHubCommandTypes.SET_TARE]: {
        keys: ["number", "value"]
    },
    [ScaleHubCommandTypes.SET_SCALE]: {
        keys: ["number", "value"]
    },
    [ClimateDriverCommandTypes.SET_CLOCK_MENU]: {
        keys: ["currentCurveDay", "recorderEraseTime", "stages"]
    },
    [ClimateDriverCommandTypes.SET_SERVICE_MENU]: {
        keys: ["analogOutputs", "chimneyVentilatorsCount", "cooling", "floorHeating", "heatersCount", "heatingType",
            "language", "mastersCount", "memoryLock", "minimumRotation", "objectDynamics", "rotation55PercentCalibration",
            "temperatureSensorsCalibration", "ventilationCycleTime", "ventilationIntensity"]
    },
    [DispenserNRFCommandTypes.SET_CONFIG_STANDARD]: {
        keys: ["curveNr", "offset", "percentCorrection", "punishment", "stage", "startTime"]
    },
    [DispenserDriverCommandTypes.SET_PARAM_OUTS]: {
        parser: (msg, DevID, isSet) => {
            let array = [];
            for (let row of msg) {
                let path = `Settings.Configuration.${DispenserDriverCommandTypes.SET_PARAM_OUTS}[${row.number - 1}]`;
                let obj = {
                    DevID,
                    key: path,
                    value: {
                        isSet,
                        SetTime: new Date().getTime(),
                        ...row
                    }
                };
                array.push(obj);
            }
            return array;
        }
    },
    [DispenserNRFCommandTypes.SET_FEED_EFC_TIME]: {
        keys: ["feedOverTime", "id", "nr"]
    },
    [DispenserNRFCommandTypes.SET_SWITCH_MOTOR_ON_INFO]: {
        keys: ["state"]
    },
    [GatawayCommandTypes.SET_SCHEDULE_OUTPUT]: {
        keys: ["nr", "intervals"]
    },
    [DispenserNRFCommandTypes.SET_MINUS_CONFIG]: {
        parser: (msg, DevID, isSet) => {
            let array = [];
            msg.forEach((row, index) => {
                let path = `Settings.Configuration.${DispenserNRFCommandTypes.SET_MINUS_CONFIG}[${index}]`;
                let obj = {
                    DevID,
                    key: path,
                    value: {
                        isSet,
                        SetTime: new Date().getTime(),
                        ...row
                    }
                };
                array.push(obj);
            })
            return array;
        }
    }
};

class ConfigurationParser {

    _msg;

    constructor(message) {
        this._msg = message;
    }

    parseCommand(DevID, isSet) {
        let commands = Array.isArray(this._msg.Command) ? this._msg.Command : [this._msg.Command];
        let configuration = [];
        for (let command of commands) {
            let parser = messageParse[command];
            if (parser) {
                if (parser.parser) {
                    let parsed = parser.parser(this._msg.CData, DevID, isSet);
                    if (Array.isArray(parsed)) {
                        configuration = [...configuration, ...parsed];
                    } else {
                        configuration.push(parsed);
                    }
                } else {
                    let value = {};
                    let path = `Settings.Configuration.${command}`;
                    if (this._msg.CData.number || this._msg.CData.nr) path += `[${(this._msg.CData.number || this._msg.CData.nr) - 1}]`;
                    let obj = {
                        DevID,
                        key: path,
                        value
                    };
                    for (let key of parser.keys) {
                        let data = this._msg.CData[key];
                        if (data !== undefined) {
                            value[key] = data;
                        }
                    }
                    if (Object.keys(value).length > 0) {
                        value.isSet = isSet;
                        value.SetTime = new Date().getTime();
                        configuration.push(obj);
                    }
                }
            }
        }
        if (configuration.length > 0) {
            return configuration;
        } else {
            return null;
        }
    }

}

export default ConfigurationParser;
