const initialValue = {
    loading: false
};

export default function dataLoaderReducer(state = initialValue, action) {
    switch (action.type) {
        case "DATA_LOADER_STATE":
            return {
                ...state,
                loading: action.payload
            };
        case "CHANGE_FARM":
        case "USER_LOGOUT_FULFILLED": {
            return initialValue;
        }
        default:
            return state
    }
}
