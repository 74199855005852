const initialValue = {
    path: "/",
    haveToRedirect: false,
};

export default function historyReducer(state = initialValue, action) {
    switch (action.type) {
        case "GOTO": {
            return {...state, path: action.payload}
        }
        case "REDIRECT": {
            return {...state, path: action.payload, haveToRedirect: true}
        }
        case "REDIRECT_END": {
            return {...state, haveToRedirect: false}
        }
        case "USER_LOGOUT_FULFILLED":
        case "CHANGE_FARM": {
            return initialValue;
        }
        default:
            return state
    }
}
