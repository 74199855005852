import {preEvents} from "../utils/AnimalDocumentsUtils";
import Animal from "../beans/Animal";
import Group from "../beans/Group";
import {flatten, get, isEmpty, isEqual, isNil} from "lodash";
import {getClass} from "../utils/EventUtils";
import TechnologyGroup from "../beans/TechnologyGroup";

export const initialValue = {
    selectedItem: null,
    events: [],
    fetchingEvent: false,
    cycleTable: []
};

/*
   Flow działania dokumentów lochy:
   1. INICIALIZACJA ZWIERZAT -> "INVENTORY_INITIALIZE"
   2. wybór zwierzeta -> "ANIMAL_DOCUMENTS_CHANGE_SELECTED_ITEM"
   3. zaladowanie eventów -> "LIST_EVENT_4_ANIMAL_FULFILLED"
   Opcionalne
   Gdy zwierzeta sie zmienia wolane jest INVENTORY_INITIALIZE i nowy obiekt swini sie znajduje w paylodzie to podmieniamy w dokumentach lochy
   Gdy dojdzie event to wolany jest LIST_EVENT_FULFILLED i wtedy dodawany jest do state.events oraz liczone jest cycleTable na nowo
 */

export default function animalDocumentsReducer(state = initialValue, action) {
    let R;
    switch (action.type) {
        case "ANIMAL_DOCUMENTS_CHANGE_SELECTED_ITEM": {
            const {payload: {item}} = action;
            const newSelectedItem = (item instanceof Group || item instanceof Animal || item instanceof TechnologyGroup)
                ? item : null;
            return {
                ...initialValue,
                selectedItem: newSelectedItem
            }
        }
        //jesli zmieni sie lista zwierzat to jest wolane inventory initialize w widoku gdzie sa dokumenty - wiec updatujemy wybrana swinie
        case "INVENTORY_INITIALIZE": {
            const {animals, groups, technoGroups} = action.payload;
            let newSelectedItem;
            if (state.selectedItem instanceof Animal) {
                newSelectedItem = animals.find(a => a.AnmID === state.selectedItem.AnmID);
            }
            if (state.selectedItem instanceof Group) {
                newSelectedItem = groups.find(g => g.AnmGrp === state.selectedItem.AnmID);
            }
            if (state.selectedItem instanceof TechnologyGroup) {
                newSelectedItem = technoGroups.find(tg => tg.TGID === state.selectedItem.TGID);
            }
            if (newSelectedItem) {
                return {
                    ...state,
                    selectedItem: newSelectedItem
                }
            }
            return state;
        }
        case "LIST_EVENT_4_ANM_PENDING": //pobieranie eventow dla pojednyczych swin
        case "LIST_EVENT_4_ANIMAL_REJECTED":
        case "LIST_EVENT_4_ANIMAL_PENDING": {
            //jesli mamy wybrana swinie i pobieramy dla niej eventy to zmieniamy stora
            if (state.selectedItem instanceof Animal) {
                if (get(action, "meta.AnmID") === state.selectedItem.AnmID) {
                    return {
                        ...state,
                        fetchingEvent: action.type.endsWith("_PENDING"),
                        events: action.type.endsWith("_PENDING") ? state.events : [],
                        cycleTable: action.type.endsWith("_PENDING") ? state.cycleTable : []
                    }
                }
            }
            return state;
        }
        case "LIST_EVENT_4_ANIMAL_FULFILLED":
            if (state.selectedItem instanceof Animal) {
                if (get(action, "meta.AnmID") === state.selectedItem.AnmID) {
                    R = preEvents(action.payload.items, action.meta.animal);
                    return {
                        ...state,
                        fetchingEvent: false,
                        events: !isEmpty(action.payload.items) ? action.payload.items.filter(ev => ev.DtaDelTime === undefined).sort((o1, o2) => o1.EvTime - o2.EvTime) : [],
                        cycleTable: R.cycleTable
                    };
                }
            }
            return state;

            // TODO - nie wiem za co ma odpowiadac fragment ten kodu po wejsciu na widok inny reducer juz to robi
        // case "LIST_EVENTS_FOR_ANIMALS_CYCLE_UPDATE":
        //     if (state.selectedItem instanceof Animal) {
        //         if (get(action, "meta.AnmID") === state.selectedItem.AnmID) {
        //             R = preEvents(action.meta.animal.events, action.meta.animal);
        //             return {
        //                 ...state,
        //                 cycleTable: R.cycleTable
        //             };
        //         }
        //     }
        //     return state;

        case "LIST_EVENTS_FOR_ANIMALS_REJECTED":
        case "LIST_EVENTS_FOR_ANIMALS_PENDING":
            if (state.selectedItem instanceof Group || state.selectedItem instanceof TechnologyGroup) {
                const key = state.selectedItem instanceof Group ? "AnmGrp" : "TGID";
                if (get(action, `meta.${key}`) === state.selectedItem[key]) {
                    return {
                        ...state,
                        fetchingEvent: action.type.endsWith("_PENDING"),
                        cycleTable: action.type.endsWith("_PENDING") ? state.cycleTable : [],
                        events: action.type.endsWith("_PENDING") ? state.events : []
                    };
                }
            }
            return state;
        case "LIST_EVENTS_FOR_ANIMALS_FULFILLED":
            if (state.selectedItem instanceof Group || state.selectedItem instanceof TechnologyGroup) {
                const key = state.selectedItem instanceof Group ? "AnmGrp" : "TGID";
                if (get(action, `meta.${key}`) === state.selectedItem[key]) {
                    return {
                        ...state,
                        fetchingEvent: false,
                        cycleTable: [],
                        events: flatten([...Object.values(action.payload)]).filter(ev => ev.DtaDelTime === undefined).sort((o1, o2) => o1.EvTime - o2.EvTime)
                    };
                }
            }
            return state;
        //jesli pobiora sie jakies eventy po wejsciu na widok
        case "LIST_EVENT_FULFILLED":
            if (state.selectedItem instanceof Animal || state.selectedItem instanceof Group || state.selectedItem instanceof TechnologyGroup) {
                let newEvents = [...state.events];
                let changed = false;
                (action.payload.items || []).forEach(e => {
                    const eventAnmID = get(e, "AnmID");
                    if (eventAnmID) {
                        const animalIDs = state.selectedItem instanceof Animal ? [state.selectedItem.AnmID] : [...state.selectedItem[state.selectedItem instanceof Group ? "AnmIDs" : "AnmList"], ...state.selectedItem.Rmvd]
                        if (animalIDs.includes(eventAnmID)) {
                            const existingEvent = newEvents.find(event => isEqual(event.EvID, e.EvID));
                            if (existingEvent && !!~newEvents.indexOf(existingEvent)) {
                                changed = true;
                                newEvents.splice(newEvents.indexOf(existingEvent), 1);
                            }
                            if (!e.DtaDelTime) {
                                changed = true;
                                newEvents.push(getClass(e));
                            }
                        }
                    }
                });
                if (changed) {
                    newEvents = newEvents.sort((o1, o2) => o1.EvTime - o2.EvTime).filter(ev => isNil(ev.DtaDelTime));
                    return {
                        ...state,
                        events: newEvents,
                        cycleTable: state.selectedItem instanceof Animal ? preEvents(newEvents).cycleTable : []
                    };
                }
            }
            return state;

        case "ANIMAL_DOCUMENTS_RESET":
        case "CHANGE_FARM":
        case "USER_LOGOUT_FULFILLED": {
            return initialValue;
        }
        default:
            return state
    }
}
